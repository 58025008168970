import React, { useEffect } from "react";

import Layout from "../components/layout";
import ContactUs from "../components/contact-us/contact-us.component";
import Accordion from "../components/accordion/accordion.component";
import { PACKAGING_REQUIREMENTS_DATA } from "../data/criteria.data";
import "../styles/app.scss";
import UsefulDocuments from "../components/useful-documents/useful-documents.component";

export default function PackagingRequirement() {

  const handleClick = (event) => {
    if (event.target.hasAttribute('data-tealium') || event.target.parentNode.hasAttribute('data-tealium')) {
      if (typeof window !== "undefined") {
        if (window.utag) {
          window.utag.link({ page_subcategory: "packaging requirements" })
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  if (typeof window !== "undefined") {
    if (window.utag) {
      window.utag.view({ page_subcategory: "packaging requirements" })
    }
  }

  return (
    <Layout>
      <section className="packaging-requirements-page page-section row">
        <div className="site-content col-xs-12 col-lg-7">
          <h1 className="page-title">Packaging requirements</h1>
          <p className="p p--lead-text">
            When you submit a full mortgage application, we'll ask you to upload relevant supporting documentation.
            Please ensure these documents are uploaded under the relevant headers to avoid delays in the underwriting
            assessment.
          </p>
          <p className="p p--bold">
            Please note that if all documents are not received within 30 days of application submission, the application will be cancelled.
          </p>

          <div className="p p--blue-box">
            <p className="p p--bold">In addition to providing the correct documentation, please ensure:</p>
            <ol>
              <li>
                The details on the documentation/evidence provided match the details submitted on the application.
              </li>
              <li>The applicant(s) name and address are the same on all the documentation provided.</li>
              <li>
                Accurate email addresses and phone numbers are included within the application for the applicant(s).
              </li>
            </ol>
          </div>

          <h2>All customers</h2>
          <ul>
            <li>Appropriate documentation to verify the customers stated income</li>
            <li>
              Bank statements are no longer needed to verify credit commitments, and will be requested by exception only
            </li>
          </ul>

          <h2>To assist with the document verification process, please ensure that:</h2>
          <ul style={{ marginBottom: 0}}>
            <li>Only one document is added per page upload</li>
            <li>The document is captured clearly (not photographed on computer screen / crumpled document / blurred photo)</li>
            <li>The details are entered on the application exactly as the document shows:
              <ul>
                <br />
                <li>Payslip shows Smith and Co then Employer name should be keyed as Smith and Co (not Smith & Co)</li>
                <li>Payslip shows HSBC (UK) Ltd then Employer name should be keyed as HSBC (UK) Ltd and not just HSBC.</li>

              </ul>
            </li>
          </ul>
          <p>Trouble shooting guide if you experience issues when uploading documents, please check:</p>
                <ul>
                  <li>Use Google Chrome</li>
                  <li>The file name should contain no special characters and not be too short a file name, e.g. only 1 letter</li>
                  <li>File size max 7MB</li>
                  <li>Acceptable files formats are <strong>PDF, JPEG or PNG only</strong></li>
                  <li>Make sure the document is not password protected</li>
                  <li>Ensure to click on &lsquo;submit&rsquo; at the bottom of the screen</li>
                </ul>


          {PACKAGING_REQUIREMENTS_DATA["accordionList"].map((d, index) => (
            <Accordion key={index} title={d.title} isTealiumLink={true}>
              {d.children.map((item, ix) => (
                <React.Fragment key={ix}>{item}</React.Fragment>
              ))}
            </Accordion>
          ))}

          <div style={{ marginTop: "40px" }}>
            <p>This website is for the use of FCA authorised mortgage intermediaries only.</p>
            <p>
              If you reproduce any information contained in this website, to be used with or advise clients, you must
              ensure it follows the FCA's advising and selling standards.
            </p>
          </div>
        </div>
        <div className="sidebar col-xs-12 col-lg-4 col-lg-offset-1 margin-bottom-double">
          <ContactUs />
          <h1 className='page-title page-title--small page-title--resource'>Useful documents</h1>
          <UsefulDocuments />
        </div>
      </section>
    </Layout>
  );
}
